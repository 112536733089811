/////////////get client ip address/////////////
import { v4 as uuidv4 } from 'uuid';


var getIPAddress = function () {
  // console.log("ghghmmmm")

  $.getJSON("https://jsonip.com")
  .done(function(data){
    //////////////////////////////////////////////////////////////////
  // console.log("ghgh")

    var lib_link = 'https://apache.historymosaicportal.com/'

    //////////////////////////////loging login attemp//////////////////////////
    var theUrl_login_track = lib_link + "/delete/" + "insert into login_attempt (ip_address) values ('" + data.ip + "')"
    var xmlHttp_login_track = new XMLHttpRequest();
    xmlHttp_login_track.open("GET", theUrl_login_track, false); // false for synchronous request
    xmlHttp_login_track.send(null);
    //////////////////////////////////////////////////////////////////////////

    ////////////////////////Liblynx////////////////////////////////////////
    document.getElementById("login0").style.display = "none";
    document.getElementById("login0").onclick = function () {
      location.replace(window.location.origin)
    }



    var libUrl = lib_link + "auth/[" + window.location.host + "," + data.ip + "," + window.location.search.replace("?", "") + "]"

    // console.log(libUrl)

    var xmlHttp = new XMLHttpRequest();
    xmlHttp.overrideMimeType("application/json");
    xmlHttp.open("GET", libUrl, false); // false for synchronous request
    xmlHttp.send(null);

    // console.log(JSON.parse(xmlHttp.responseText))
    if (JSON.parse(xmlHttp.responseText).status == 'wayf') {
      location.replace(JSON.parse(xmlHttp.responseText)._links.wayf.href);
    }
    if (JSON.parse(xmlHttp.responseText).status == 'identified') {
      document.getElementById("login0").style.display = "none";
      document.getElementById("logout").style.display = 'block';
      document.getElementById("curruser").innerHTML = "Current User: " + JSON.parse(xmlHttp.responseText).account.account_name;
      document.getElementById("curruserid").innerHTML = JSON.parse(xmlHttp.responseText).account.id;
      var id = JSON.parse(xmlHttp.responseText).account.id

      document.getElementById("username").value = JSON.parse(xmlHttp.responseText).account.account_name;

      document.getElementById("curruser").style.display = "block";
      document.getElementById("gotoprj").style.display = "block";
      document.getElementById("switch12").style.display = "block";
      let date = new Date().toLocaleString();
      let myuuid = uuidv4();


      document.getElementById("login0").setAttribute("name", myuuid);
      /////////////update the shared prj table
      var theUrl_login = lib_link + "/delete/" + "insert into user_activity (uuid,customerid, logins) values ('" + myuuid + "'," + id + ",'" + date.replaceAll(',', ' ').replaceAll('/', '-') + "')"
      var xmlHttp_login = new XMLHttpRequest();
      xmlHttp_login.open("GET", theUrl_login, false); // false for synchronous request
      xmlHttp_login.send(null);

      // if(JSON.parse(xmlHttp.responseText).auth_method ==='ip'){

      // }


    }

    ///////////////////////////////////////////////////////////////
    // console.log(data.ip);

  }) .fail(function(jqxhr, textStatus, error) {
    console.error("AJAX Request Failed: " + textStatus, error);
  });;
}

getIPAddress()







// // var lib_link = 'https://ec2-3-139-223-82.us-east-2.compute.amazonaws.com/'
// var lib_link = 'https://apache.historymosaicportal.com/'


// ////////////////////////Liblynx////////////////////////////////////////
// document.getElementById("login0").style.display="none";
// document.getElementById("login0").onclick = function(){
//   location.replace(window.location.origin)
// }

// var libUrl=lib_link+"auth/["+window.location.host+","+window.location.search.replace("?","")+"]"

// // console.log(libUrl)

// var xmlHttp = new XMLHttpRequest();
// xmlHttp.overrideMimeType("application/json");
// xmlHttp.open( "GET", libUrl, false ); // false for synchronous request
// xmlHttp.send( null );

// if(JSON.parse(xmlHttp.responseText).status == 'wayf'){
//   location.replace(JSON.parse(xmlHttp.responseText)._links.wayf.href);
// }
// if(JSON.parse(xmlHttp.responseText).status =='identified'){
//   document.getElementById("login0").style.display="none";
//   document.getElementById("logout").style.display='block';
//   document.getElementById("curruser").innerHTML="Current User: "+JSON.parse(xmlHttp.responseText).account.account_name;
//   document.getElementById("curruser").style.display="block";
//   document.getElementById("gotoprj").style.display="block";
//   document.getElementById("switch12").style.display="block";

// }